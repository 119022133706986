
function message_parent(str) {
    if ( window.location !== window.parent.location ) { 
        window.top.postMessage(["messageFromChild", str], '*');
    }
}

export function isPlugin() {
    if ( window.location !== window.parent.location ) { 
        return true;
    }
    else {
        return false;
    }
}

export const messageParent = jsonstr => {
    return message_parent(jsonstr);
};

export default messageParent;

